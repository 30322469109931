import React from "react";

const UserInfo = ({ user }) => {
    return (
        <>
            {/*<h4 className="text-muted">CSCW '22 publications:</h4>
            <div className="indent">
                {!("author" in user && (parseInt(user.author) >= 0) ) ?
                    "No paper at this conference"
                    : proceeding["papers"].filter(p => p["authors"].includes(user.author)).map((p, index) => 
                        <p key={index}>
                            <b>{p["title"]}</b> <br/>
                            {p["authors"].map((a, i) => {
                                return `${proceeding["authors"][a].split(": ")[0]} (${proceeding["authors"][a].split(": ")[1]}), `
                            })}
                        </p>)}
                        </div>*/}

            <h4 className="text-muted">Recent publications:</h4>
            <div className="indent">
                {!user.prev_papers || Object.keys(user.prev_papers).length === 0 ?
                    "No previous publication"
                    :
                    Object.values(user.prev_papers).sort((a, b) => b.year - a.year).slice(0,3)
                    .map((v, index) => 
                    <p key={index}>
                        <b>{v["title"]}</b> {v["conference"]} {v["year"]}<br/>
                        <small>{v["authors"].map(a => a['name']).join(", ")}</small>
                    </p>
                )}
            </div>
        </>
    )
};

export default UserInfo;