import React, { useEffect, useState } from "react";
import { Button, Collapse, Divider, Form, Input, InputNumber, Modal, notification, Pagination, Select, Switch, Tag, Tooltip } from "antd";

import {CheckCircleTwoTone, MailOutlined, PlusOutlined, UsergroupAddOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import '../styles/components/custom.scss';

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import UserInfo from "../components/people/UserInfo";
import { firebase_database, firebase_instance, firestore } from "../utils/database";

import useReferredState from "../utils/useReferredState";
import sendEmail from "../utils/emailNotification";
import MessageList from "../components/people/MessageList";
import proceeding from "../components/cscw2024_papers";
import stage_dict from "../utils/stage";

import { getSimiliarUsers } from "../utils/userProfiling";

import { Notification } from "../components/Notification";

import jwt_encode from "jwt-encode";

const { Panel } = Collapse;
const { Option } = Select;

let listenerSet = false;
let unsubscribe = () => {};


const Chat = () => {
    const auth = useSelector((state) => state.auth);
    const [me, setMe] = useState({});
    const [meData, setMeData] = useState({});
    const [inMeeting, setInMeeting] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [users, setUsers] = useState([]);

    const [statusList, setStatusList] = useState({});
    const [callStatusList, setCallStatusList] = useReferredState([]);
    
    const [room, setRoom] = useReferredState(null);
    const [counterpart, setCounterpart] = useReferredState(null);
    const [waiting, setWaiting] = useReferredState(true);
    const [ending, setEnding] = useReferredState(false);
    const [participants, setParticipants] = useReferredState([]);
    const [inviteEnabled, setInviteEnabled] = useState(true);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [suggestionForm] = Form.useForm();
    const [invitees, setInvitees] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(5);
    const [onlineCount, setOnlineCount] = useState(0);
    const [waitingTime, setWaitingTime] = useState(-1);
    const [loading, setLoading] = useReferredState(true);

    const history = useNavigate();
    const joinNotification = new Notification();
    const fiveMinNotification = new Notification();

    let api = null;
    let timer = null;

    let msgInit = false;

    useEffect(() => {
      checkMeetingNumber();
      console.log(ending);

      window.addEventListener("beforeunload", beforeLeave);
      window.addEventListener("hashchange", beforeLeave);

      getUsers();
      getStatusList();
      getCallStatusList();

      return () => {
        console.log("unmount");
        beforeLeave();
        unsubscribe();
      }
    }, []);

    useEffect(() => {
      if (waiting.current) {
        var timeLeft = 40;
        setWaitingTime(timeLeft);

        const timer = setInterval(function () {
          if (timeLeft <= 0){
            clearInterval(timer);
          }
          setWaitingTime(timeLeft);
          timeLeft -= 1;

        }, 1000);
      }
    }, [waiting]);

    const beforeLeave = async (e) => {
      console.log("beforeLeave");

      if (e) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }

      await endMeeting();

      if (api) {
          api.executeCommand('hangup');
      }

      joinNotification.key && joinNotification.close();

      // hack for status check
      const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
      userStatusDatabaseRef.set({
          state: 'offline',
          updatedAt: Date.now(),
      });

      const roomRef = await firestore.collection("room").doc(room.current.id);
      const roomData = (await roomRef.get()).data();
      if(roomData !== undefined){
        roomRef.set({
          participants: roomData.participants,
          public: roomData.public,
          requests: roomData.requests,
          title: roomData.title,
          creationTime: roomData.creationTime,
          lastLeave: Date.now(),
          max: roomData.max,
        });
      }

      fiveMinNotification.key && fiveMinNotification.close();

    }

    const accept = async (requesterId, roomId) => {
      joinNotification.close(requesterId);

      const ref = await firestore.collection("meeting").doc(requesterId);
      const data = (await ref.get()).data();

      console.log("* accept");
      console.log(data);

      if (data.status === "queue") {
        // if user is on a queue, let them in
        ref.update({
          status: "talking",
        });
      }
      else {
        // remove user from the reqeuster array if the user is no longer on a queue
        const roomRef = await firestore.collection("meeting").doc(roomId);
        const roomData = (await roomRef.get()).data();
        const newRequests = roomData?.requests.filter(r => r.id !== requesterId);
                
        if (newRequests) {
          roomRef.update({
            requests: newRequests
          });
        }
      }
    }

    const decline = async (requesterId) => {
      const ref = await firestore.collection("meeting").doc(requesterId);

      ref.set({
          matched: "",
          meetingNumber: "",
          status: "",
          host: "",
      });

      joinNotification.close(requesterId);
    }

    const checkMeetingNumber = async () => {
      const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);
      const me = await meRef.get();
      const myData = me.data();

      setMe(myData);
      debugger;

      if (!myData.meetingNumber || myData.meetingNumber.length === 0) {
        history("/");
      }
      else if (!inMeeting) {
        setInMeeting(true);
        startMeeting(myData.meetingNumber);
        getRoom(myData.meetingNumber);

        if (myData.matched.length > 0) {
          const userData = await loadUser(myData.matched);
          setCounterpart(userData);
        }

        if (myData.status === "invitee" || myData.status === "guest" || myData.status === "talking") {
          setWaiting(false);
        }
        setLoading(false);
        
        unsubscribe = firestore.collection("room").doc(myData.meetingNumber).onSnapshot(doc => {
            console.log(room.current);
            console.log(participants);

            console.log(doc);

            const newRoom = {...doc.data(), id: doc.id};

            console.log(newRoom);
            setRoom(newRoom);
            getParticipants(newRoom.participants);

            if (newRoom.requests && newRoom.requests.length > 0) {
              for (let request of newRoom.requests) {
                if (request.receiver === auth.currentUser.uid && window.location.pathname.includes("chat")) {
                  joinNotification.open({
                    message: `${request.name} wants to join this conversation`,
                    description: request.note,
                    btn: <>
                      <Button type="primary" className="mr-1" size="small" onClick={() => accept(request.id, newRoom.id)}>
                        Accept
                      </Button>
                      <Button type="primary" size="small" onClick={() => decline(request.id)} danger>
                        Maybe later
                      </Button>
                      <audio id="audio" src="sounds/alarm_matched.mp3" type="audio/mp3" autoPlay={true}></audio>
                    </>,
                    key: request.id, 
                    duration: 0,
                  });
                }
              }
            }

            // hack for status check
            makeUserOnline();
        });
      }
    }

    const makeUserOnline = async () => {
      // hack for status check
      const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
      userStatusDatabaseRef.set({
          state: 'online',
          updatedAt: Date.now(),
      });
    }

    const getRoom = async (roomId) => {
      const roomRef = await firestore.collection("room").doc(roomId).get();
      if (roomRef.exists) {
        const roomInfo = roomRef.data();
        setRoom({...roomInfo, id: roomId});
        getParticipants(roomInfo.participants);
      }
      else {
        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);

        meRef.update({
          meetingNumber: "",
          status: "",
        });
        
        clearTimeout(timer);
        history("/");
      }
    }

    const getParticipants = async (participantList) => {
      if (participantList) {
        const promises = participantList.map(async pid => {
          const p = await loadUser(pid);
          return p;
        });

        const roomParticipants = await Promise.all(promises);

        // update the list of invitees
        const newInvitees = invitees.filter(u => !roomParticipants.map(p => p.id).includes(u));
        setInvitees(newInvitees);

        console.log(newInvitees.length, roomParticipants.length);
        if(roomParticipants.length + newInvitees.length >= 5) {
          setInviteEnabled(false);
        } else {
          setInviteEnabled(true);
        }

        

        setParticipants(roomParticipants);

        // add to database that they have met here
        try {
          firestore.collection('settings').doc(auth.currentUser.uid).update({
            met: firebase_instance.firestore.FieldValue.arrayUnion(...roomParticipants.map(r => r.id))
          });
        } catch (error) {
          firestore.collection('settings').doc(auth.currentUser.uid).set({
            met: roomParticipants.map(r => r.id)
          }, {merge: true});
        }
        
        
      }
    }

    const loadUser = async (uid) => {
      const userRef = await firestore.collection("settings").doc(uid);
      const user = await userRef.get();
      const userData = {
          ...user.data(), 
          id: user.id,
      };

      return userData;
    }

    const startMeeting = (meetingNumber) => {
      try {
        const domain = "who2chat2.csail.mit.edu";
  
        const token = jwt_encode({
            "context": {
                "user": {
                    "avatar": "https:/gravatar.com/avatar/abc123",
                    "name": "New user",
                    "email": "newuser@example.com",
                    "id": "xvdf:a1b2c3-d4e5f6-0abc1-23de-abcdef01fedcba"
                }
            },
            "nbf": ((new Date().getTime()) - 60 * 60 * 1000)/1000,
            "aud": "jitsi",
            "iss": "who2chat.com",
            "sub": domain,
            "room": "*",
            "exp": ((new Date().getTime()) + 60 * 60 * 1000)/1000
        }, process.env.REACT_APP_JITSI_SECRET_KEY);
  
        const options = {
            roomName: meetingNumber,
            height: 500,
            jwt: token,
            parentNode: document.getElementById("jitsiContainer"),
            interfaceConfigOverwrite: {
                filmStripOnly: false,
                MOBILE_APP_PROMO: false,
                HIDE_INVITE_MORE_HEADER: true,
                SHOW_CHROME_EXTENSION_BANNER: false,
                DISPLAY_WELCOME_PAGE_CONTENT: false,
                DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
                TOOLBAR_BUTTONS: ['microphone', 'camera', 'filmstrip', 'closedcaptions', 'select-background', 'chat', 'fullscreen', 'desktop', 'videoquality', 'tileview', 'hangup'],
            },
            configOverwrite: {
                hideConferenceSubject: true,
                disableSimulcast: false,
                disableDeepLinking: true,
                useStunTurn: true,
                prejoinPageEnabled: false,
                remoteVideoMenu: {
                    disableKick: true,
                },
                disableRemoteMute: true,
                disableRemoteControl: true,
            },
        };

        api = new window.JitsiMeetExternalAPI(domain, options);

        // add listener 
        api.addEventListener('participantJoined', async (obj) => {
            // show "You're talking to" instead of "You're waiting for"
            if (waiting.current) {
              setWaiting(false);
              const meRef = firestore.collection("meeting").doc(auth.currentUser.uid);
              await meRef.update({
                matched: "",
                status: "talking",
              });
            }

            // set 5-minute notification
            setTimer();      
            // online status hack
            const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
            userStatusDatabaseRef.set({
                state: 'online',
                updatedAt: Date.now(),
            });      
        });
        api.addEventListener('videoConferenceJoined', async (obj) => {
          api.executeCommand('displayName', auth.currentUser.displayName);


          if (!waiting.current) {
            const meRef = firestore.collection("meeting").doc(auth.currentUser.uid);
            await meRef.update({
              matched: "",
              status: "talking",
            });
          }
        });
        api.addEventListener("participantLeft", async (obj) => {
            console.log(obj);
            const numberOfParticipants = api.getNumberOfParticipants();
            if (numberOfParticipants !== participants.current.length) {
              console.log(numberOfParticipants);
              console.log(participants.current);
            }
        });
        api.addEventListener("videoConferenceLeft", async (obj) => {
            console.log(obj);
            endMeeting().then(async () => {
              history("/");
              const roomRef = await firestore.collection("room").doc(room.current.id);
              const roomData = (await roomRef.get()).data();
              if(roomData !== undefined){
                roomRef.set({
                  participants: roomData.participants,
                  public: roomData.public,
                  requests: roomData.requests,
                  title: roomData.title,
                  creationTime: roomData.creationTime,
                  lastLeave: Date.now(),
                  max: roomData.max,
                });
      }
            })
        });
      } 
      catch (err) { console.error(err); }
    }

    const setTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(async function() {
        if (window.location.pathname.includes("chat")) {
          fiveMinNotification.open({
            message: "It has been 5 minutes since you started this conversation",
            description: "How about trying to talk to new people?",
            duration: 10
          })
        }

        // hack for status check
        const userStatusDatabaseRef = await firebase_database.ref(`/status/${auth.currentUser.uid}`);
        userStatusDatabaseRef.set({
            state: 'online',
            updatedAt: Date.now(),
        });
      }, 5 * 60000);
    }

    // when the host cancels the call, let the guest know about it
    const initializeGuest = async (userId) => {
      const userRef = await firestore.collection("meeting").doc(userId);
      userRef.set({
          meetingNumber: "",
          matched: "",
          status: "canceled",
          host: "",
      });

      const now = new Date();
      // const messageId = userId + auth.currentUser.id + now.getMonth() + now.getDate() + now.getHours() + now.getMinutes();
      const ref = await firestore.collection("message").doc();
      ref.set({
          message: `${auth.currentUser.displayName} called you but you missed it.`,
          receiver: userId,
          sender: auth.currentUser.uid,
          senderName: auth.currentUser.displayName,
          date: new Date()
      });
    }

    const endMeeting = () => {
      return new Promise(async (resolve, reject) => {
        console.log(ending);
        if (ending.current) return;

        setEnding(true);
        console.log("endMeeting");

        unsubscribe();

        // if I was waiting for one person, let that person know that I canceled the invitation
        if (waiting.current && counterpart.current) {
          console.log(counterpart.current);
          initializeGuest(counterpart.current.id);

           // send email to the counterpart that they missed a call
           sendEmail(`Missed call from ${auth.currentUser.displayName}`, `${auth.currentUser.displayName} just called you at Who2chat but seems like you missed it! Visit Who2chat to call back.`, counterpart.current);
        }
        // if room info is available, delete or update the room 
        if (room && room.current && room.current.participants) {
          console.log(room);
          const roomRef = await firestore.collection("room").doc(room.current.id);
          if (room.current.participants.length <= 1) {
            // if I am the only one left, delete the room
            await roomRef.delete();
          }
          else {
            // if other people are left, just remove me from the participant list
            const newParticipants = room.current.participants.filter(u => u !== auth.currentUser.uid);
            console.log(newParticipants);
            await roomRef.update({
              participants: newParticipants
            });
          }
        }

        const meRef = await firestore.collection("meeting").doc(auth.currentUser.uid);

        meRef.update({
          meetingNumber: "",
          status: "",
        });
        
        clearTimeout(timer);

        resolve();
      });
    }

    const inviteUser = (invitee) => {
      const userRef = firestore.collection("meeting").doc(invitee.id);
      
      // trigger change so they know they will be invited
      userRef.set({
        matched: auth.currentUser.uid,
        meetingNumber: me.meetingNumber,
        status: "invitee",
        host: auth.currentUser.displayName,
      }, {merge: true});

      setInvitees([...invitees, invitee.id]);

      if([...invitees, invitee.id].length >= 4 ) {
        setInviteEnabled(false);
      } 

      document.dispatchEvent(new CustomEvent("invite", { detail: {
          invitees: invitee.id
      }}));
    }

    const handleOk = (e) => {
      // save the suggested user in db
      setIsModalVisible(false);

      suggestionForm.resetFields();

      // alert(`${auth.currentUser.uid} suggests ${invitees.map(u => u.name).join()} to meet ${currentSuggestionUser.name} to talk about ${suggestionTopics}`);

      // Initializing a write batch...
      var batch = firestore.batch();
     

      invitees.map(u => {
          const userRef = firestore.collection("meeting").doc(u.id);

          // trigger change so they know they will be invited
          batch.set(userRef, {
            matched: auth.currentUser.uid,
            meetingNumber: me.meetingNumber,
            status: "invitee",
            host: auth.currentUser.displayName,
          }, {merge: true});

          return null;
      })

      batch.commit();
      
      // update suggestionMade to rerender 
      // let newsuggestionMade = {...suggestionMade};
      // newsuggestionMade[currentSuggestionUser.id] = [... new Set( // remove dup
      //     (suggestionMade[currentSuggestionUser.id] || []).concat(invitees.map(s => s.id)) )]; 
      // setSuggestionMade(newsuggestionMade);  

      document.dispatchEvent(new CustomEvent("invite", { detail: {
          invitees: invitees.map(s => s.id)
      }}));

  };

    const handleCancel = () => {
      setIsModalVisible(false);

      suggestionForm.resetFields();
    };

    const onVisibilityChange = () => {
      const newVisibility = !room.current.public;

      const newRoom = {...room.current, public: newVisibility};
      setRoom(newRoom);
      setDisabled(false);
    }

    const onMaxChange = (value) => {
      const newRoom = {...room.current, max: value};
      setRoom(newRoom);
      setDisabled(false);
    }

    const onTitleChange = (e) => {
      const value = e.currentTarget.value;

      const newRoom = {...room.current, title: value};
      setRoom(newRoom);
      setDisabled(false);
    }

    const onRoomChange = async () => {
      await firestore.collection("room").doc(room.current.id).update({
        public: room.current.public,
        max: room.current.max,
        title: room.current.title,
      });

      notification.open({
        message: "Success",
        description: "Successfully Saved!"
      });

      setDisabled(true);
    }

    console.log(room.current)

    const getUsers = async () => {
      // get all users except myself
      const snapshot = await firestore.collection('settings').get(); // load all the users in the system
      let newUsers = snapshot.docs.filter(doc => doc.id != auth.currentUser.uid) // exclude myself
                          .filter(doc => doc.data().name) // filter who set their name
                          .map(doc => {
                              return ({
                                  ...doc.data(), 
                                  id: doc.id,
                              })
                          });
      const meData = snapshot.docs.filter(doc => doc.id == auth.currentUser.uid) // exclude myself
        .map(doc => {
            return ({
                ...doc.data(), 
                id: doc.id,
            })
        })[0];

      

      // if no setting set, redirect to Settings
      const currentUser = snapshot.docs.filter(doc => doc.id === auth.currentUser.uid);
      if (currentUser.length === 0) {
          history("/settings");
          return;
      }

      setMeData(meData);

      // get wishlist users
      const w_snapshot = await firestore.collection('wishlist').doc(auth.currentUser.uid).get();
      const newWishUsers = (w_snapshot.data()) ? newUsers.filter(user => user.id in w_snapshot.data() && w_snapshot.data()[user.id]["wished"]) : [];

      
      debugger;
      // can't use isinwishlist() because wishUser is not set by the time calling this line
      setUsers( getSimiliarUsers(meData, newUsers) );


      // setWishUsers(newWishUsers);

      // // get suggested-to-meet users
      // const s_snapshot = await firestore.collection('suggestion').doc(auth.currentUser.uid).get(); // load list of suggested people `auth.currentUser.uid)` to talk
      // let newSuggestions = {};
      // newUsers.filter(user => user.id in (s_snapshot.data() || {}))
      //     .map(user => {newSuggestions[user.id] = {
      //     "suggested_by": Object.keys(s_snapshot.data()[user.id]), 
      //     "topics": Object.keys(s_snapshot.data()[user.id]).map(k => s_snapshot.data()[user.id][k])
      // }; return null;});
      // setSuggestions(newSuggestions);

      // // get suggestion made by this user
      // const smade_snapshot = await firestore.collection('suggestion_made').doc(auth.currentUser.uid).get(); // load list of suggested made by `auth.currentUser.uid)`
      // let newSuggestionsMade = {};
      // newUsers.filter(user => user.id in (smade_snapshot.data() || {}))
      //     .map(user => {newSuggestionsMade[user.id] = smade_snapshot.data()[user.id]; return null;});
      // setSuggestionMade(newSuggestionsMade);   
      
      
      // let h = {};
      // for (const user of [...newUsers,...wishUsers]) {
      //     try {
      //         let a = [];// list of people this user havemet
      //         if(user.met)
      //             a = user.met.filter(uid => {
      //                 // if this is user themselves don't count
      //                 if([user.id,  auth.currentUser.uid].includes(uid)) return false;
      //                 return true;
      //             }
      //         ).map(uid => isJunior(uid))  
      //         h[user.id] = {junior: a.filter(a => a == true).length > 0, senior: a.filter(a => a == false).length > 0}
      //       } catch (error) {
      //           console.log(error);
      //         h[user.id] = {junior: false, senior: false}
      //       }
      //   }
        
      // setHadChatWith(h);
      // console.log(hadChatWith);
      // console.log(junior);
  }

    const getStatusList = async () => {
      const ref = await firebase_database.ref(`/status`);
      const statusRef = await ref.get();
      const status = statusRef.val();

      setStatusList(status);

      ref.on("value", (snapshot) => {
          const newStatus = snapshot.val();
          setStatusList(newStatus);
      })
    }

    const getCallStatusList = async () => {
      const ref = firestore.collection("meeting");
      const snapshot = await ref.get();

      let newCallStatusList = snapshot.docs.map(doc => {
          const data = doc.data();
          return ({
              available: !(data.meetingNumber && data.meetingNumber.length > 0), 
              waiting: data.meetingNumber && data.meetingNumber.length > 0 && (data.status === "queue" || data.status === "host" || data.status === "guest"),
              meetingNumber: data.meetingNumber,
              id: doc.id,
          })
      });

      setCallStatusList(newCallStatusList);

      // listener for changes
      ref.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
              const data = change.doc.data();
              // console.log(data);
              const listWithoutUser = callStatusList.current.filter(user => user.id !== change.doc.id);
              const newList = [...listWithoutUser, {
                  id: change.doc.id, 
                  meetingNumber: data.meetingNumber,
                  waiting: data.meetingNumber && data.meetingNumber.length > 0 && (data.status === "queue" || data.status === "host" || data.status === "guest"),
                  available: !(data.meetingNumber && data.meetingNumber.length > 0),
              }];

              setCallStatusList(newList);
          });
      });

  }

  const renderOnlineUses = (newStatusList, newCallStatuslist) => {
    let online_panels = [];

    users.forEach(function(user, index) {
      const isMe = me.id === user.id
      const status = (newStatusList && user.id in newStatusList) ? newStatusList[user.id].state : "offline";
      const callStatus = newCallStatuslist.current.filter(s => s.id === user.id);
      const isOnline = (status === "online");
      const isAvailable = (newCallStatuslist.length === 0 || (newCallStatuslist.length > 0 && newCallStatuslist[0].available));
      const isWaiting = (callStatus.length > 0 && callStatus[0].waiting);
      const roomId = (newCallStatuslist.length > 0) ? newCallStatuslist[0].meetingNumber : "";

      if(!isOnline || !isAvailable || isWaiting) return;

      online_panels.push( (
        
        <Panel 
            style={{width: "800px"}}
            id={`${user.id}`}
            header={
                <span>
                    <>
                        <Tooltip title={isMe || isOnline ? "online": "offline"}><span className={`circle ${isMe || isOnline ? "green": "gray"}`}></span></Tooltip>
                        <b className="username">{user.name}{isMe ? " (me)":""}</b>, {stage_dict[user.stage]} at {user.affiliation}&nbsp; 
                        ((meData.wantsToMeet.length && meData.wantsToMeet.includes(user.id)) && <Tooltip title="They left a msg that they want to talk to u"><Tag color="orange">Wants to meet you</Tag></Tooltip>)
                    </>
                </span>
            } 
            key={index} 
            extra={<Button disabled={!inviteEnabled} onClick={(e) => inviteUser(user)}>Invite</Button>}
        >

            { user.website &&
                <>
                <div className="">
                    <a href={user.website} target="_blank">{user.website}</a>
                </div>
                </>
            }

            {"selectedInterests" in user ? <h4 className="text-muted"><b>{user.selectedInterests.join(", ")}</b></h4>: ""}
            {"commonTerms" in user && (user.author == "-1") && (!user.prev_papers || Object.keys(user.prev_papers).length === 0) ? <h4 className="text-muted">Keywords of common interests: <b>{user["commonTerms"].join(", ")}</b></h4>: ""}
            
            {/* <h4 className="text-muted">CSCW '22 publications:</h4>
            <div className="indent">
                {!("author" in user && user.author in proceeding["authors"]) ?
                    "No paper at this conference"
                    : proceeding["papers"].filter(p => p["authors"].includes(user.author)).map((p, index) => 
                        <p key={index}>
                            <b>{p["title"]}</b> <br/>
                            {p["authors"].map((a, i) => {
                                const author = users.filter(u => (user.author !== u.author) && (u.author === a));
                                return( author.length ?
                                    <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}>{author[0]["name"]} ({author[0]["affiliation"]}),</a>
                                    : 
                                    `${proceeding["authors"][a].split(": ")[0]} (${proceeding["authors"][a].split(": ")[1]}), `
                                )
                            })}
                        </p>)}
            </div> */}

            <h4 className="text-muted">Recent publications:</h4>
            <div className="indent">
                {!user.prev_papers || Object.keys(user.prev_papers).length === 0 ?
                    "No previous publication"
                    :
                    Object.values(user.prev_papers).sort((a, b) => b.year - a.year).slice(0,3)
                    .map((v, index) => 
                    <p key={index}>
                        <b>{v["title"]}</b> {v["conference"]} {v["year"]}<br/>
                        {v["authors"].map((a,i) => {
                            const author = users.filter(u => (u.id != user.id) && (u.selectedMatch && u.selectedMatch.map(uu => uu.id).includes(a.authorId)));
                            return author.length ? 
                                <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}><small>{author[0]["name"]} ({author[0]["affiliation"]}),</small></a>:
                                <small>{a["name"]}, </small>})}
                    </p>
                    // .filter((v, index) => (user.prev_papers[v]["conference"] != "Proceedings of the ACM on Human-Computer Interaction") && (user.prev_papers[v]["year"] == 2021))
                    
                )}
            </div>


                <Divider/>
        </Panel>
    ));
    }, this);

    // setOnlineCount(online_panels.length);

    return online_panels;
  }

  const renderUsersWrapper = (users) => {

    const rendered = renderUsers(users);
    return rendered.length ? 
      rendered.map(
        (data, index) =>
          index >= minIndex &&
          index < maxIndex && (
            <Collapse expandIconPosition="right" ghost>
            <Collapse expandIconPosition="right" ghost>
                {data}
            </Collapse>       
          </Collapse>
          )
      )
      : <span>Currently no one is free to join at the moment! Join other conversation or wait until someone is free.</span>;


  }

  const renderUsers = (users) => {
    let online_panels = [];

    users.forEach(function(user, index) {
      const isMe = me.id === user.id
      const status = (statusList && user.id in statusList) ? statusList[user.id].state : "offline";
      const callStatus = callStatusList.current.filter(s => s.id === user.id);
      const isOnline = (status === "online");
      const isAvailable = (callStatus.length === 0 || (callStatus.length > 0 && callStatus[0].available));
      const roomId = (callStatus.length > 0) ? callStatus[0].meetingNumber : "";

      if(!isOnline || !isAvailable) return;

      online_panels.push( (
        
        <Panel 
            style={{width: "800px"}}
            id={`${user.id}`}
            header={
                <span>
                    <>
                        <Tooltip title={isMe || isOnline ? "online": "offline"}><span className={`circle ${isMe || isOnline ? "green": "gray"}`}></span></Tooltip>
                        <b className="username">{user.name}{isMe ? " (me)":""}</b>, {stage_dict[user.stage]} at {user.affiliation}&nbsp; 
                        {(meData.wantsToMeet.length > 0 && meData.wantsToMeet.includes(user.id)) && <Tooltip title="They left a msg that they want to talk to u"><Tag color="orange">Wants to meet you</Tag></Tooltip>}
                    </>
                </span>
            } 
            key={index} 
            extra={<Button disabled={!inviteEnabled} onClick={(e) => inviteUser(user)}>Invite</Button>}
        >

            { user.website &&
                <>
                <div className="">
                    <a href={user.website} target="_blank">{user.website}</a>
                </div>
                </>
            }

            {"selectedInterests" in user ? <h4 className="text-muted"><b>{user.selectedInterests.join(", ")}</b></h4>: ""}
            {"commonTerms" in user && (user.author == "-1") && (!user.prev_papers || Object.keys(user.prev_papers).length === 0) ? <h4 className="text-muted">Keywords of common interests: <b>{user["commonTerms"].join(", ")}</b></h4>: ""}
            
            {/*<h4 className="text-muted">CSCW '22 publications:</h4>
            <div className="indent">
                {!("author" in user && user.author in proceeding["authors"]) ?
                    "No paper at this conference"
                    : proceeding["papers"].filter(p => p["authors"].includes(user.author)).map((p, index) => 
                        <p key={index}>
                            <b>{p["title"]}</b> <br/>
                            {p["authors"].map((a, i) => {
                                const author = users.filter(u => (user.author !== u.author) && (u.author === a));
                                return( author.length ?
                                    <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}>{author[0]["name"]} ({author[0]["affiliation"]}),</a>
                                    : 
                                    `${proceeding["authors"][a].split(": ")[0]} (${proceeding["authors"][a].split(": ")[1]}), `
                                )
                            })}
                        </p>)}
                          </div>*/}

            <h4 className="text-muted">Recent publications:</h4>
            <div className="indent">
                {!user.prev_papers || Object.keys(user.prev_papers).length === 0 ?
                    "No previous publication"
                    :
                    Object.values(user.prev_papers).sort((a, b) => b.year - a.year).slice(0,3)
                    .map((v, index) => 
                    <p key={index}>
                        <b>{v["title"]}</b> {v["conference"]} {v["year"]}<br/>
                        {v["authors"].map((a,i) => {
                            const author = users.filter(u => (u.id != user.id) && (u.selectedMatch && u.selectedMatch.map(uu => uu.id).includes(a.authorId)));
                            return author.length ? 
                                <a key={i} style={{color: "blue", textDecoration: "underline"}} href={`#${author[0].id}`}><small>{author[0]["name"]} ({author[0]["affiliation"]}),</small></a>:
                                <small>{a["name"]}, </small>})}
                    </p>
                    // .filter((v, index) => (user.prev_papers[v]["conference"] != "Proceedings of the ACM on Human-Computer Interaction") && (user.prev_papers[v]["year"] == 2021))
                    
                )}
            </div>


                <Divider/>
        </Panel>
    ));
    }, this);

    // setOnlineCount(online_panels.length);

    return online_panels;
  }

  const pageChange = (page) => {
    setCurrentPage(page);
    setMinIndex((page - 1) * 5);
    setMaxIndex(page * 5);
  };

    return (
        <>
            {inMeeting   ?
                <>
                    {/*<MessageList isPeopleTab={false} />*/} 
                    { <div>
                      <h2>Available users</h2>
                      {renderUsersWrapper(users)}
                      <Pagination
                        pageSize={5}
                        current={currentPage}
                        total={Object.keys(statusList).filter(k => statusList[k]["state"] == "online").length}
                        onChange={pageChange}
                        style={{ bottom: "0px" }}
                      />
                    </div>}<br/>
                    
                    <div className="jitsi">
                      <div className="jitsi-container" >
                        <div id="jitsiContainer"></div>
                        <Button type="primary" danger onClick={() => history(-1)}>Leave this room</Button>
                      </div>
                      <div className="jitsi-sidebar">
                        <div className="scrollable">
                          {loading.current ? "Loading..." : <>
                            {waiting.current && counterpart.current ?
                              <>
                                You are waiting for <br/>
                                <b>{counterpart.current.name}</b> at {counterpart.current.affiliation}
                                <br/>
                                {waitingTime <= 0 ? 
                                  <div>The invitation has expired. You can leave the room now.</div>
                                  :
                                  <div>You can wait for {waitingTime} more seconds</div>
                                }
                              </>
                              :
                              
                              <>
                                {room.current && 
                                <>
                                  <div className="mb-2">
                                    <h4><b>Room Title</b></h4>
                                    <Input placeholder="What topic are you going to talk about?" value={room.current.title} onChange={onTitleChange} />
                                  </div>

                                  <h4><b>Participants</b></h4>
                                  <Collapse>
                                    {invitees.length? <Panel key={-1} header={`${invitees.length} users invited`} >
                                      </Panel>: ""}
                                  {participants.current.map((user, i) => 
                                    <Panel key={i} header={user.id === auth.currentUser.uid ? user.name + "(me)" : user.name} >
                                      <UserInfo user={user} />
                                    </Panel>
                                  )}
                                  </Collapse>
                                  <br/>

                                  <div className="mb-2">
                                    <h4><b>Room visibility</b> &nbsp;
                                    <Tooltip title="If public, anyone can join this room right away. If private, people may ask one of the participants whether they can join the room or not."><QuestionCircleOutlined /></Tooltip>
                                    </h4>
                                    <Switch checkedChildren="Public" unCheckedChildren="Private" checked={room.current.public} onChange={onVisibilityChange} />
                                  </div>

                                  <Button className="mb-2" type="primary" disabled={disabled} onClick={onRoomChange}>Save Settings</Button>
                                </>
                                }
                              </>
                            }
                          </>}
                        </div>
                      </div>
                    </div>
                </>
                :
                "Loading..."
            }
        </>
    )
}

export default Chat;
