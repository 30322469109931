import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import Navbar from "./components/Navbar";
import Auth from "./pages/Auth";
import People from "./pages/People";
import Rooms from "./pages/Rooms";
import Settings from "./pages/Settings";
import Landing from "./pages/Landing";
import Admin from "./admin/Admin";
import Sidebar from "./components/Sidebar";
import { firebase_auth, firestore } from "./utils/database";
import userModel from "./utils/userProfileModel";
import { loginUserAction, logoutUserAction } from "./redux/reducers/authReducer";
import CheckStatus from "./components/CheckStatus";
import Chat from "./pages/Chat";
import Faq from "./pages/Faq";

const AppRouter = () => {
    const [init, setInit] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const dispatch = useDispatch();

    const getUrlParameter = (sParam) => {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }

    const handleLogin = async (acmUsername) => {
        // create account
        let data = await firebase_auth.createUserWithEmailAndPassword(`${acmUsername}@who2chat.org`, "p@ssword").then(function(result) {
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
          }).catch(function(error) {
            // Handle error.
            firebase_auth.signInWithEmailAndPassword(`${acmUsername}@who2chat.org`, "p@ssword")
        });
    }

    const handleInviteCode = async (uid) => {
        const old_settings = await firestore.collection("settings-old")
            .doc(uid)
            .get();

        if(old_settings.exists) {
            const data = old_settings.data();

            firestore.doc(`settings/${uid}`).set({...data, met: []});

            firestore.collection('settings-old').doc(uid).delete();

            return;
        }
        
        const settings = await firestore.collection("settings")
            .doc(uid)
            .get();
            
        if (!settings.exists || (settings.exists && !settings.data().verified)) 
            await firestore.collection("settings").doc(uid).set({
                ...userModel,
                verified: true,
            }, {merge: true});
    }

    useEffect(() => {
        // enforce https
        if (window.location.protocol !== 'https:' && !window.location.href.includes("localhost")) {
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }   

        const acmUsername = getUrlParameter("username");
        firebase_auth.onAuthStateChanged((user) => {
            if (user !== null) {
                const invite_code = getUrlParameter("invite_code");
                if(invite_code && invite_code == "cscw") {
                    handleInviteCode(user._delegate.uid);
                }
                
                dispatch(loginUserAction(user));
                setLoggedIn(true);
            }
            else {
                dispatch(logoutUserAction());
                setLoggedIn(false);

                // this will be triggered when users try to authenticate with ACM without gmail
                //  check username param if exist, then create with email & password user or login
                if(acmUsername) {
                    if(acmUsername === "undefined")
                        alert("Sorry there was an error logging into your ACM account. Please try again");
                    else
                        handleLogin(acmUsername);
                }
            }
            setInit(true);
        });
    }, [dispatch]);

    return (
        <Router>
            {init ?
                <>
                    {loggedIn ?
                        <>
                            <Layout className="site-layout">
                                <Navbar />
                                <CheckStatus>
                                    <Routes>
                                        <Route exact path="/" element={<People />}>
                                            
                                        </Route>
                                        <Route exact path="/home" element={<People />}>

                                        </Route>
                                        <Route exact path="/rooms" element={<Rooms />}>

                                        </Route>
                                        <Route exact path="/chat" element={<Chat />}>

                                        </Route>
                                        <Route exact path="/settings" element={<Settings />}>

                                        </Route>
                                        <Route exact path="/faq" element={<Faq />}>

                                        </Route>    
                                        <Route exact path="/admin" element={<Admin />}>

                                        </Route>
                                    </Routes>
                                </CheckStatus>
                            </Layout>
                            <Sidebar />
                        </>
                        :
                        <>
                            <Routes>
                                <Route exact path="/home" element={<Landing />}>
                                </Route>
                                <Route exact path="/" element={<Auth />}>
                                </Route>
                                <Route exact path="*" element={<Landing />}>
                                </Route>
                            </Routes>
                        </>
                    }
                </>
                :
                    "Loading..."
                }
            
        </Router>
    )

}

export default AppRouter;
