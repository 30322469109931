import React, {useEffect} from "react";

import { Checkbox, Form, Input } from 'antd';
import {MinusCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons';

const { Search } = Input;

const SearchProfile = ({ name, searchMatch, selectedMatch, removeSelectedMatch, addSelectedMatch, handleSearchMatchChange }) => { 
    useEffect(() => {
        if(name)
            searchMAGAuthor(name); 
    }, []);

    const isInSelectedMatch = (entryId) => {
        const filtered = selectedMatch.filter(v => v.id === entryId);
        return filtered.length > 0;
    }

    const searchMAGAuthor = (search_text) => {
        // if the user provie url, then just parse it
        if(search_text.includes("academic.microsoft.com/author/")) {
            let user_mag_id = search_text.split("academic.microsoft.com/author/")[1].split("/publication")[0];

            let author_url = `https://api.labs.cognitive.microsoft.com/academic/v1.0/evaluate?expr=Id=${user_mag_id}&count=1&attributes=LKA.AfN,Id,PC,DAuN`;
            
            fetch(author_url, {
                headers: {
                    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MAG_SUB_KEY,
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(res => res.json())
            .then(result => {
                console.log(result)

                const entities = formatSearchResult(result).flat();
                handleSearchMatchChange(entities);
            })
        }
        else { // if a name in plain text, then interpret
            // normalize the query
            let normalized_text = search_text.toLowerCase().replace(" ", "%20");
        
            // request search result data
            let query_url= `https://api.semanticscholar.org/graph/v1/author/search?query=%27${normalized_text}%27`
            fetch(query_url, 
                {
                headers: {
                    // 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_MAG_SUB_KEY,
                    'Content-Type': 'application/json',
                    // 'x-api-key': process.env.REACT_APP_SS_API_KEY,
                    // 'Content-Type': 'application/x-www-form-urlencoded',
            }}
            )
            .then(res => res.json())
            .then(result => {
                // if no search results, show "No results" 
                if (result["total"] === 0) {
                    handleSearchMatchChange(["none"]);
                    return;
                }
    
                // get author profile data
                Promise.all(
                    result["data"].map(w => {
                        let author_id = w["authorId"];
                        let author_url = `https://api.semanticscholar.org/graph/v1/author/${author_id}?fields=name,papers.authors,papers.title,papers.url,papers.year,affiliations,paperCount`;
    
                        return fetch(author_url, 
                            {
                            headers: {
                                'Content-Type': 'application/json',
                                // 'x-api-key': process.env.REACT_APP_SS_API_KEY,
                            }
                        }
                        )
                    })
                ).then(authors_list => {
                    // process the author profile data
                    let newSearchMatch = [];
    
                    for (let i = 0; i < authors_list.length; i++) {
                        const v = authors_list[i];
                        console.log(authors_list);
    
                        v.json()//.then(res => res.json())
                        .then(result => {
                            console.log(result)
                            
                            const entities = formatSearchResult(result);
    
                            // render new search results
                            newSearchMatch = [...newSearchMatch, entities].flat();
                            handleSearchMatchChange(newSearchMatch);
    
                        });
                    }
                })
            }, (error) => {
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                alert(error)
            })
        }
        
    }

    const formatSearchResult = (result) => {
        return {
            "name": result["name"],
            "id": result["authorId"],
            "affliation": result["affiliations"].length ? result["affiliations"][0] : "",
            "publication_cnt": result["paperCount"],
            "recent_work": result["papers"].length ? result["papers"][0]["title"]: ""
        };
    }

    return(
        <Form.Item
            name="prev_papers"
        >
            <h3>Search your profile(s) from the academic system:</h3>

            {/* added names */}
            {selectedMatch.map((entry, index) => 
                <span style={{display: ""}} id={entry.id} key={entry.id}>

                    <span id={`entry-${entry.id}`} onClick={(e) => removeSelectedMatch(e)}>
                        <Checkbox checked/>&nbsp;
                    </span>

                    <b>{entry.name}</b> at {entry.affliation} &nbsp; <span className="text-muted">{entry.publication_cnt} publication(s), e.g., {entry.recent_work}</span><br/>
                </span>
            )}

            {/* search names */}
            <Search
                placeholder="The name under which you publish"
                className="mb-3"
                allowClear
                enterButton="Search"
                size="large"
                defaultValue={name}
                onSearch={(v) => searchMAGAuthor(v)}
            /> 

            {/* add names */}
            {searchMatch === ["none"] ? 
                "No result."
                :
                <>
                    {searchMatch.filter(v => !isInSelectedMatch(v.id)).map((entry, index) => 
                        <span id={entry.id} key={entry.id}>
                            <span id={`entry-${entry.id}`} onClick={(e) => addSelectedMatch(e)}>
                                <Checkbox /> &nbsp;
                            </span>
                            <b>{entry.name}</b> {entry.affliation && ("at" + entry.affliation)} &nbsp; <span className="text-muted">{entry.publication_cnt} publication(s), e.g., {entry.recent_work}</span>
                            <br/>
                        </span> 
                    )}
                </>
            }
            
        </Form.Item>
    )
}

export default SearchProfile;