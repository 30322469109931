import { Configuration, OpenAIApi } from "openai";
const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
});
const openai = new OpenAIApi(configuration);

const computeQuery = async (userQuery) => {
    const prompt = `There is a list of researchers in an array. In each element, it includes their expertise and interest, such as {"name": "Soya Park", "expertise": "A Qualitative Study of Patient Experiences in Healthcare", "interest": "This study uses in-depth interviews and thematic analysis to explore the experiences of patients in healthcare settings."}. And there are two functions: getSimiliarity(s1, s2): return cosine similiarity of two strings. getSynonym(s): return a list of synonym of s. 
    Convert this text to a programmatic command:
    
    Example: who is the most relevant researcher to me? 
    Output: getSimiliarity | my.expertise | expertise |

    Example: Who are experts in the area of my interest?
    Output: getSimiliarity | my.interest | expertise |
    
    Example: who are experts in [keyword]?  
    Output: getSynonym | expertise | [keyword] |
    
    Example: "Who should I ask about qualitative research?"
    Output:  getSynonym | expertise | "qualitative research", "qualitative", "interpretive", "phenomenological", "grounded theory", "ethnographic", "case study", "thematic analysis" |
    
    Example: "Who is the expert in the area of my interest?"
    Output: getSimiliarity | my.interest | expertise |
    
    Example: "Who is taller than 6 feet?"
    Output: N/A
    
    Example: "Who has similiar interest with me?"
    Output: getSimiliarity | my.interest | interest |
    
    Example: Who should I learn about my area of interest?
    Output:  getSimiliarity | my.interest | expertise |
    
    Example: Who is interested in my expertise?
    Output:   getSimiliarity | my.expertise | interest |
    
    Example:Who is interested in researching misinformation?
    Output:  getSynonym | interest | "misinformation", "disinformation", "false information", "fake news" |
    
    Example: Who is working on human-ai collaboration? 
    Output:  getSynonym | expertise | "human-ai collaboration", "Human-in-the-loop", "Augmented intelligence", "Human-AI teaming", "Human-AI partnership", "Explainable AI", "Human-centered AI", "Human-robot interaction", "Collaborative AI", "Human-AI co-creation", "Hybrid intelligence" |
    
    Example: Who is working on different topics with me?
    Output:  getSimiliarity | my.expertise | expertise | not 
    
    Example: Who has no interest in my expertise?
    Output:  getSimiliarity | my.expertise | interest | not
    
    Example: Which researcher is not working on misinformation?
    Output: getSynonym | expertise | "misinformation", "disinformation", "false information", "fake news" | not

    Example: Who is going to CHI 2023?
    Output:  N/A
    
    Example: Who is complementary in terms of social capital?
    Output:  getNetworkDistance | me  |  | not
    
    Example: Who are my colleagues?
    Output:  getNetworkDistance | me |  |
    
    Example: I want to meet someone new. Who should I meet?
    Output:  getNetworkDistance | me |  | not
    
    Example: I want to meet someone handsome. Who should I meet?
    Output:  N/A
    
    Example: Who has worked with David Karger?
    Output:  getNetworkDistance | David Karger |  |
    
    Example: Which research co-authored with Dan Ross?
    Output:  getNetworkDistance | Dan Ross |  |

    Example: Who can introduce me to Ross?
    Output:  getNetworkDistance | Ross |  |

    Example: Who is working on something similiar like David?
    Output: getSimiliarity | David.expertise | expertise |
    
    Example: Who are someone who is working on something like Malone's research who I can talk to intead of Malone? 
    Output:  getSimiliarity | Malone.expertise | expertise
    
    Example: Who share research interest with Amy?
    Output:  getSimiliarity | Amy.interest | interest |
    
    Example: I want to learn more about my interest from an expert. 
    Output:  getSimiliarity | my.interest | expertise |    `;

    // const response = await openai.createCompletion({
    //     model: "text-davinci-003",
    //     prompt: prompt,
    //     temperature: 0,
    //     max_tokens: 256,
    //     top_p: 1,
    //     frequency_penalty: 0,
    //     presence_penalty: 0,
    //   });

    // return response["data"]["choices"][0]["text"].trim();
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + String(process.env.REACT_APP_OPENAI_API_KEY)
        },
        body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  {
                    "role": "system",
                    "content": prompt
                  },
                  {
                    "role": "user",
                    "content": `${userQuery}
    Output:`
                  }
                ],
                temperature: 0,
                max_tokens: 256,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
              })
      };
      const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
      const data = await response.json();
      return data['choices'][0]['message']['content'];
}

const handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Stupid network Error'
    }));
};

const explainResult = async (docs) => {
    const prompt = `Write a pararaph of common theme of papers above. Do not explain about each paper such as "The first paper explores.. " but focus on similiarity: 
    
    The common theme between papers above is `;

    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + String(process.env.REACT_APP_OPENAI_API_KEY)
        },
        body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  {
                    "role": "system",
                    "content": prompt
                  },
                  {
                    "role": "user",
                    "content": docs.join('\n - ')
                },
                  // {
                  //   "role": "system",
                  //   "content": `You analyze if the given instruction from a user follows this guideline below:\n\n\"${value}\"\n\nYour response should be either \"yes\" or \"no\".`
                  // },
                  // {
                  //   "role": "user",
                  //   "content": r['taboo']
                  // },
                ],
                temperature: 0.7,
                max_tokens: 227,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
              })
      };

    const response = await (fetch("https://api.openai.com/v1/chat/completions", requestOptions).catch(handleError));
    if (response.ok) {
    } else {
        return " ";
    }

    const data = await response.json();

    return data['choices'][0]['message']['content'];
}

const explainKeywords = async (docs, keywords) => {
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + String(process.env.REACT_APP_OPENAI_API_KEY)
        },
        body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [
                  {
                    "role": "system",
                    "content": `Given the work, write a pararaph on how the work above in terms of the keywords: ${keywords}`
                  },
                  {
                    "role": "user",
                    "content": `${docs.join('\n - ')}`
                  }
                ],
                temperature: 0.7,
                max_tokens: 227,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
              })
      };

      const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
      const data = await response.json();

      return data['choices'][0]['message']['content'];
}

export default computeQuery;
export {explainKeywords, explainResult};