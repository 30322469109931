import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { refreshUserAction } from "../redux/reducers/authReducer";
import firebase from "firebase/compat/app";
import { firebase_instance, firestore } from "../utils/database";

import { Button, Form, Input, Select, Steps, Row, Col, Modal, notification, Radio } from 'antd';

import {FileDoneOutlined} from '@ant-design/icons';


import SearchProfile from "../components/settings/SearchProfile";
// import SelectKeywords from "../components/settings/SelectInterests";
import PaperPick from "../components/PaperPick";

import proceeding from "../components/cscw2024_papers";
import stage_dict from "../utils/stage";
import userModel from "../utils/userProfileModel";
import isMyColleague, { conf_ID, getColleaguesSS, getSimiliarUsers, getLikedContent } from "../utils/userProfiling";
import authorListFormat from "../utils/authorListFormat";


const { Option } = Select;
const { Step } = Steps;

const Settings = () => {
    // shared state using redux
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const mounted = useRef();

    // states in this component
    const [current, setCurrent] = useState(-1); // for Steps
    const [infoForm_ref] = Form.useForm();

    const [selectedMatch, setSelectedMatch] = useState([]);
    const [searchMatch, setSearchMatch] = useState([]);
    const [name, setName] = useState("");
    const [affiliation, setAffiliation] = useState("");
    const [website, setWebsite] = useState("");
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [stage, setStage] = useState("undergraduate");
    const [prev_papers, setPrevPapers] = useState({});
    const [author, setAuthor] = useState(-1);
    const [verified, setVerified] = useState(false);
    const [acmUsername, setAcmUsername] = useState(null);
    const [token, setToken] = useState("");
    const [askToken, setAskToken] = useState(true); // if they are going to use SIGCHI app or not
    const [myLikeIds, setMyLikesIds] = useState([]);
    const [oldUsers, setOldUsers] = useState([]);
    const [similiarUsers, setSimiliarUsers] = useState([]);

    const [isPaperModal, setIsPaperModal] = useState(false);
    const [loading, setLoading] = useState(true);

    let users; // settings of users in the system. Fetched on-demand

    // things that go into componentDidMount
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            getSettings();
            getInterests();
        } else {
          }   
          

        const acmToken = getUrlParameter("token");
          
        if(!acmToken) return; 

        setToken(acmToken);
        window.history.replaceState(null, null, window.location.pathname);

        

        fetchData(acmToken);
            
    }, []);

    async function fetchData(acmToken) {
        const my_liked = await getLikedContent(acmToken);

        let my_liked_ids = [];
        if (my_liked['myLists'].length) {
            my_liked_ids = my_liked['myLists'][0]['myListItems'].filter(p => p.conferenceId === conf_ID).map(p => p.contentId);
        }
    
        my_liked_ids = my_liked_ids.concat( my_liked['myScheduleSessions'].filter(p => p.conferenceId === conf_ID).map(p => p['myScheduleContents']).flat().map(p => p['contentId']) );

        my_liked_ids = my_liked_ids.map(p => "" + p);

        setMyLikesIds(my_liked_ids);
        
        await firestore.doc(`settings/${auth.currentUser.uid}`).update({
            token: acmToken,
            myLikeIds: my_liked_ids.map(id => ""+ id) // make sure to save as string
        })

        if(my_liked_ids.length < 2) {
            // not enough paper. tell them to mark more papers. 
            setAskToken(false);
            setIsPaperModal(true);

            const modal = Modal.error({
                title: `Not enough marked papers to make recommendations`,
                content: `Who2chat needs to know papers that you are interested. Please mark some papers here!`,
              });

            return;
        }

        const my_liked_contents = proceeding["papers"].filter(p => my_liked_ids.includes(p.id));
        console.log("my_liked_contents", my_liked_contents);
        console.log(my_liked_contents.filter(p => !p['abstract']));
        
        // recommend some people based on their liked data and other's papers
        if(!users)
            await fetchProfiles();

        const simUsers = getSimiliarUsers({id: auth.currentUser.uid, myLikeIds: my_liked_ids}, users.filter(u => u.id !== auth.currentUser.uid));
        console.log(simUsers)
        setSimiliarUsers(simUsers); 
    }

    const getInterests = async () => {
        await firestore.collection("interest").onSnapshot(snapshot => {
            const interestArray = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
        });
    }

    const getOldSettings = async () => {
        const snapshot = await firestore.collection('settings-old').get(); // load all the users in the system
        let oldUsers = snapshot.docs
            .map(doc => {
                const d = {...doc.data(), id: doc.id};
                
                return d;
            });
            
        setOldUsers(oldUsers);
    }

    const filterCurrentLikes = (m) => {
        return m.filter(l => proceeding["papers"].filter(p => (p["id"]+ "") === l).length)
    }

    const getSettings = async () => {
        // get the settings data from firestore collection
        const settings = await firestore.collection("settings")
                        .doc(auth.currentUser.uid)
                        .get();

        // if the data exist, save it as state
        if (settings.exists) {
            const data = settings.data();

            // render the stored data
            setName(data.name || auth.currentUser.displayName);
            setAuthor(data.author || -1);
            setAffiliation(data.affiliation || "");
            setStage(data.stage);
        
            if(!data.name || !data.affiliation) {
                getOldSettings();
                setCurrent(0);
            }
            else if(data.myLikeIds.length < 2)
                setCurrent(1);
            else if(data.selectedMatch.length == 0)
                setCurrent(2);
            else
                setCurrent(3);

            setToken(data.token);

            if(data.token)
                fetchData(data.token);
            else
                setMyLikesIds(filterCurrentLikes(data.myLikeIds) || []);

            setSelectedMatch(data.selectedMatch || []);

            
            setWebsite(data.website);
            setSelectedInterests(data.selectedInterests);
            
            setPrevPapers(data.prev_papers || {});
            setVerified(data.verified || false);
            setAcmUsername(data.acmUsername);

            
        }
        // if the data doesn't exist, create new document
        else {
            const old_settings = await firestore.collection("settings-old")
                .doc(auth.currentUser.uid)
                .get();

            if(old_settings.exists) {
                const data = old_settings.data();
                
                // render the stored data
                setName(data.name);
                setAuthor(data.author);
                setAffiliation(data.affiliation);
                setStage(data.stage);
            
                if(!data.name || !data.affiliation) 
                    getOldSettings();
                // else if( filterCurrentLikes(data.myLikeIds).length < 2)
                    setCurrent(1);
                // else if(data.selectedMatch.length == 0)
                //     setCurrent(2);
                // else
                //     setCurrent(3);
    
                setToken(data.token);
                if(data.token)
                    fetchData(data.token);
                else
                    setMyLikesIds( [] );
    
                setSelectedMatch(data.selectedMatch);
    
                
                setWebsite(data.website);
                setSelectedInterests(data.selectedInterests);
                
                setPrevPapers(data.prev_papers);
                setVerified(data.verified || false);
                setAcmUsername(data.acmUsername);

                console.log(data);
                firestore.doc(`settings/${auth.currentUser.uid}`).set({...data, met: []});

                firestore.collection('settings-old').doc(auth.currentUser.uid).delete();
            }
            else {
                await firestore.collection("settings").doc(auth.currentUser.uid).set({
                    ...userModel,
                    name: auth.currentUser.displayName,
                    email: auth.currentUser.email, 
                }, {merge: true});

                // for profile creation
                getOldSettings();
            }  
        }
        setLoading(false);
    }

    const getUrlParameter = (sParam) => {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }
    
    const fetchProfiles = async () => {
        // update the database who is my colleague
        const snapshot = await firestore.collection('settings').get(); // load all the users in the system
        users = snapshot.docs.filter(doc => doc.data().name) // filter who set their name
                            .map(doc => {
                                return ({
                                    ...doc.data(), 
                                    id: doc.id,
                                })
                            });
    }

    const onStepSubmit = async (values: any) => {
        console.log("Success:", values);

        if(current == 0) {
            // if using pre-exising profile, save that data into the profile and remove that from settings-old
            if(values['author'] > -1) {
                // token not transferred? 
                const user_data = {
                    ...oldUsers[values['author']],
                    met: [],
                    name: name,
                    author: values['author'],
                    affiliation: values['affiliation'] || "",
                    stage: values['stage'],
                    verified: true,
                    email: auth.currentUser.email, 
                };
    
                await firestore.doc(`settings/${auth.currentUser.uid}`).update(user_data);
    
                // import everything
                setToken(oldUsers[values['author']].token);
                setMyLikesIds(oldUsers[values['author']].myLikeIds);
    
                setSelectedMatch(oldUsers[values['author']].selectedMatch);
                setPrevPapers(oldUsers[values['author']].prev_papers);
    
                firestore.collection('settings-old').doc(oldUsers[values['author']].id).delete();
            }
            else {
                const user_data = {
                    name: name,
                    author: values['author'],
                    affiliation: values['affiliation'] || "",
                    stage: values['stage'],
                    email: auth.currentUser.email, 
                    verified: true,
                };
    
                await firestore.doc(`settings/${auth.currentUser.uid}`).update(user_data);
            }

        }
        else if (current == 1) {
            if(token) {
                
            }
        }
        else if (current == 2) {
            // TODO also add their paper corpus 
            await firestore.doc(`settings/${auth.currentUser.uid}`).update({
                prev_papers: prev_papers,
                selectedMatch: selectedMatch,
            });

            if(!users)
                await fetchProfiles();
            
            const my_setting = users.filter(doc => doc.id == auth.currentUser.uid)[0];
            const my_colleagues = users.filter(u => u.id !== auth.currentUser.uid)
                .filter(u => isMyColleague(my_setting, u))
                .map(u => u.id);

            if(my_setting.selectedMatch.length){
                const c1 = await getColleaguesSS(my_setting.selectedMatch.map(s => s.id));
                console.log(c1);

                const c1_ids = c1.map(c => c['authorId']);

                const c2 = await getColleaguesSS( c1_ids );
                const c22 = c2.filter(sid => !c1_ids.includes(sid)).slice(0, 20000);
                console.log(c22);

                firestore.collection('network').doc(auth.currentUser.uid).set({"coauthor1": c1, "coauthor2": c22}, {merge:true});
            }
                

            await firestore.doc(`settings/${auth.currentUser.uid}`).update({
                colleagues: my_colleagues
            });             
            
            // also add the current user as colleague to other's people setting
            var batch = firestore.batch();
            my_colleagues.forEach(function(id) {
                const userRef = firestore.collection("settings").doc(id);

                batch.update(userRef, {
                    colleagues: firebase_instance.firestore.FieldValue.arrayUnion(auth.currentUser.uid),
                  });
            }, this);

            batch.commit();

            // setPrevPapers({});
            // setSearchMatch([]);
            // setSelectedMatch([]);
        }

        setCurrent(current + 1);
    };

    const infoForm = () => {
        if (current == 0)
            return (
                <Form name="basic" form={infoForm_ref} onFinish={onStepSubmit} onFinishFailed={onFinishFailed} initialValues={{
                    author: author,
                    affiliation: affiliation,
                    stage: stage || "undergraduate",
                }} onFieldsChange={(changedValues, allValues) => {
                    console.log(allValues);

                    if(allValues[0]['value'] > -1) {
                        setName( oldUsers[allValues[0]['value']]['name'] );
                        setStage( oldUsers[allValues[0]['value']]['name']['stage'] );
                    } else {
                        setStage( allValues[2]['value'] );
                    }
                    setAuthor( allValues[0]['value'] );
                    setAffiliation(allValues[1]['value']);
                    

                }}>
                <Form.Item
                    label="Your name"
                    name="author"
                    rules={[{ required: true, message: "Please input your full name!" }]}
                >
                    <Select
                        showSearch
                        placeholder="Harry Potter"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        { 
                            console.log(input, option)
                            return !option.children || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            
                        }
                        filterSort={(optionA, optionB) =>
                            !optionA.children.join || !optionB.children.join || optionA.children.join().toLowerCase().localeCompare(optionB.children.join().toLowerCase())
                        }
                        onSearch={(v) => setName(v)} // for custom name 
                        onSelect={(v) => {
                            if( v > -1) {
                                // if they register before, auto-complete their name and affiliation based on old profile info
                                infoForm_ref.setFieldsValue({ affiliation: oldUsers[v]["affiliation"] });
                                infoForm_ref.setFieldsValue({ stage: oldUsers[v]["stage"] });
                            }    
                        }}
                    >
                        {name && <Option key={`pub-custom`} value={-1}>{name}</Option>}
                        {oldUsers.map((v, index) => {
                            const authorInfo = v['name'] + ", " + v['affiliation']
                            return <Option key={`pub-${index}`} value={index}>{authorInfo}</Option>
                        })}
                    </Select>
                </Form.Item>

                {/* input your affiliation */}
                <Form.Item
                    name="affiliation"
                    label="Your current affiliation"
                    >
                    <Input 
                        placeholder="Hogwarts University"
                    />
                </Form.Item>

                <Form.Item label="I'm" name="stage">
                    <Select>
                        {Object.keys(stage_dict).map(s => <Option value={s}>{stage_dict[s]}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                    Next
                    </Button>
                </Form.Item>
                </Form>
            );
        else return `${name} `;//`${name} at ${affiliation}`;
        // <div>
        //   <label for="name">Your name: </label><input id="name" type="text" placeholder="This will be displayed to other users" />
        //   <label for="affliation">Your affliation: </label><input id="affliation" type="text" placeholder="This will be displayed to other users" />
        //   {current === 0 && nextBtn}</div>
    };

    const interestLoad = () => {
        const a = proceeding["papers"];

        const likedInConf = myLikeIds.filter(l => proceeding["papers"].filter(p => (p["id"]+ "") === l).length);
        if (current == 1) return <>
            <span>Who2chat is going to make a recommendation based on your interests. Please share your interest to get recommendations!</span><br/>
            <span>Are you using SIGCHI app to mark your paper interest? </span>
            <Radio.Group defaultValue={askToken ? "yes":"no"} onChange={(e) => setAskToken(e.target.value == "yes")}>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
            </Radio.Group><br/><br/>
            {askToken ? 
                <Button type="primary">
                    <a href={`https://programs.sigchi.org/login?clientId=b4c00fab-fc1d-4952-aae3-6caa4db11370&callback=${process.env.REACT_APP_CALLBACK_URL}`}>Import papers you marked in the SIGCHI program app</a>
                </Button>
                :<Button type="primary" onClick={() => setIsPaperModal(true)}>
                Mark papers you are interested in
            </Button>}
            <br/><br/>
            {(likedInConf || null) && <><h1>Some papers that you liked (you marked {likedInConf.length} papers in total)</h1>
                <ul>
                    {likedInConf.slice(0, 5).map(l => <li>{proceeding["papers"].filter(p => (p["id"]+ "") === l)[0]['title']}</li>)} 
                </ul></>}

            <Button type="default" onClick={(e) => setCurrent(current-1)}>
                Previous
            </Button>
            <Button type="primary" onClick={onStepSubmit} disabled={likedInConf.length < 2}>
                Next
            </Button>
            </>;
            // return <>
            // <Button type="primary" onClick={() => setIsPaperModal(true)}>
            //     Mark papers you are interested in
            // </Button>
            // // {!token && <span>Who2chat is going to make a recommendation based on your interests. Please import your schedules to get recommendations!</span>}
            // // <Button type="primary">
            // //     <a href={`https://programs.sigchi.org/login?clientId=b4c00fab-fc1d-4952-aae3-6caa4db11370&callback=${process.env.REACT_APP_CALLBACK_URL}`}>Import your papers you added at the SIGCHI app</a>
            // // </Button>
            // // <p>{token && "Your papers in the list are successfully loaded. "}</p>
            // // {token && <><h1>Some papers that you liked</h1>
            // //     <ul>
            // //         {myLikeIds.length ? myLikeIds.slice(0, 5).map(l => <li>{proceeding["papers"].filter(p => p["id"] === l)[0]['title']}</li>): <li>You don't have any paper marked. Please go <a target="_blank" rel="noopener noreferrer" href="https://programs.sigchi.org/chi/2022/program/2-may">here</a>, like a few papers, and try again! </li> }
                    
            // //     </ul></>}
            
            // <Button type="default" onClick={(e) => setCurrent(current-1)}>
            //     Previous
            // </Button>
            // <Button type="primary" onClick={onStepSubmit}>
            //     Next
            // </Button></>;
        else {
            if(token)
                return `Your starred papers are successfully loaded (${likedInConf.length} papers)`;
        }
    };

    const profilePick = () => {
        if (current == 2)
        return (
            <>
            <SearchProfile 
                name={name}
                searchMatch={searchMatch}
                selectedMatch={selectedMatch}
                addSelectedMatch={addSelectedMatch}
                removeSelectedMatch={removeSelectedMatch}
                handleSearchMatchChange={handleSearchMatchChange}
            />
            <br />
            <Button type="default" onClick={(e) => setCurrent(current-1)}>
                Previous
            </Button>
            <Button type="primary" onClick={onStepSubmit} disabled={!selectedMatch.length}>
                Next
            </Button>
            {["undergraduate", "graduate", "industry"].includes(stage) &&
                <Button type="default" onClick={(e) => setCurrent(current + 1)} disabled={selectedMatch.length}>
                My academic profile does not exist (skip)
            </Button>}
            
            </>
        );
    };

    const done = () => {
        if (current == 3)
        return (
            <><p>Your profile is complete! Check out recommended researchers to meet and reaching out to people <Button type="primary" href="/">here</Button></p></>
        );
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const countDown =() => {
        let secondsToGo = 3;
        const modal = Modal.success({
          title: `Taking you to ACM in ${secondsToGo} second`,
          content: `You will redirected to Who2chat once logged in`,
          okText: "Cancel",
          okType: "default"
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            title: `Taking you to ACM in ${secondsToGo} second`,
          });
        }, 1000);

        

        setTimeout(() => {
          clearInterval(timer);
          window.location.replace("https://programs.sigchi.org/login?callback=https://who2chat.com/settings");
        }, secondsToGo * 1000);
      }

    const saveSettings = async () => {
        let newWebsite = website;

        // if website does not have http/https add it
        if (website && !website.startsWith("http")) {
            newWebsite = "https://" + website;
            setWebsite(newWebsite);
        }

        // update affiliation and selectedMatch
        await firestore.doc(`settings/${auth.currentUser.uid}`).update({
            name: name,
            affiliation: affiliation,
            website: newWebsite || "",
            selectedMatch: selectedMatch,
            selectedInterests: selectedInterests || [],
            author: author,
            updatedAt: Date.now(),
            verified: verified
        })

        // update name
        await auth.currentUser.updateProfile({
            displayName: name,
        });

        dispatch(refreshUserAction({...auth.currentUser, displayName: name}));

        // alert that it is saved
        notification.open({
            message: "Success",
            description: "Successfully Saved!"
        });
    }

    const removeSelectedMatch = (e) => {
        const v = e.currentTarget;

        // id of the entry to be deleted
        const entryId = v.id.substring(6,);

        // delete the entry from the current selectedMatch array
        const newSelectedMatch = selectedMatch.filter((v, index) => v.id !== ""+entryId);

        // render the new selectedMatch array
        setSelectedMatch(newSelectedMatch);

        // remove the deleted author's papers
        let ref = firebase.firestore().collection("settings").doc(auth.currentUser.uid);
        
        let to_delete = {};
        const to_delete_id = Object.keys(prev_papers).filter(p => prev_papers[p]["author_mag_id"] === entryId);
        to_delete_id.map(f => {
            to_delete["prev_papers." + f] = firebase.firestore.FieldValue.delete();
            delete prev_papers[f];
            return null;
        })
        ref.update(to_delete);
        setPrevPapers(prev_papers);
    }

    const addSelectedMatch = (e) => {
        const v = e.currentTarget;

        // id of the entry to be added
        const entryId = v.id.substring(6,);

        // select entry to be added from searchMatch array
        const entry = searchMatch.filter(e => e.id === "" + entryId)[0];

        // add the entry to the current selectedMatch array
        const newSelectedMatch = [...selectedMatch, entry];

        // render the new selectedMatch array
        setSelectedMatch(newSelectedMatch);

        setName(entry["name"]);

        // add recent papers from MAG that will be shown in the user's profile
        let query_url= `https://api.semanticscholar.org/graph/v1/author/${entry.id}?fields=name,papers.authors,papers.title,papers.url,papers.year,papers.journal,papers.fieldsOfStudy,papers.abstract,affiliations,paperCount`;
        fetch(query_url, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-api-key': process.env.REACT_APP_SS_API_KEY,
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
        )
        .then(res => res.json())
        .then(paper_list => {
            // process the recent papers
            let papers = {};
            const non_arxivs = paper_list["papers"] // .filter(p =>  !("VFN" in p && p["VFN"].includes("arXiv")));
            if(true) {
                const d = new Date();
                
                non_arxivs.sort((a,b) => b.year - a.year);
                non_arxivs.filter(p => d.getFullYear() - p["year"] < 5) // add only papers of past 5 years
                    .map(p => {
                    papers[p["paperId"]]= {
                        "author_mag_id": entry.id, 
                        "title": p["title"],
                        "authors": p["authors"],
                        "year": p["year"],
                        "conference": p["journal"]? (p["journal"]["name"] || "") : "",
                        "abstract": p["abstract"]
                    }
                    return null;
                })

                
            }
                
            else
                paper_list["entities"].slice(0,2).map(p => {
                    papers[p["Id"]]= {
                        "author_mag_id": entry.id, 
                        "title": p["DN"],
                        "authors": p["AA"].map(a => `${a["DAuN"]} (${a["DAfN"]})`).join(", "),
                        "year": p["Y"],
                        "conference": p["VSN"] || p["VFN"] || ""
                    }
                    return null;
                })

            papers = {...prev_papers, ...papers};
            firestore.collection('settings').doc(auth.currentUser.uid).set({"prev_papers": papers}, {merge:true});
            setPrevPapers(papers);
        })
    }

    const checkRegCode = () => {
        
        if(document.getElementById("regcode").value === process.env.REACT_APP_REG_CODE )    
     { 
            setVerified(true);

            setCurrent(0);
        }
        else {
            alert("Incorrect registration code. Please submit the code that you receive from the chair.");
        }
    }

    const handleSearchMatchChange = (newSearchMatch) => {
        // render new search results
        setSearchMatch(newSearchMatch);
    }

    const handleNameChange = (e) => {
        // render new affiliation
        // setName(e.currentTarget.value);
    }

    const handleWebsiteChange = (e) => {
        // render new website
        setWebsite(e.currentTarget.value);
    }

    return(  
    <>
    <Row>
        <Col md={24} lg={12}>
            {loading ? "Loading..."
            :
            <>
            {verified ? 
            <div>
                <Steps direction="vertical" current={current} {...{[name && selectedMatch.length ? "onChange": "_ignore"]: (v) => setCurrent(v)}}>
                    <Step title="Your infomation" description={infoForm()} />
                    <Step title="Your interests" description={interestLoad()} />
                    <Step title="Your academic profile" description={profilePick()} />
                    <Step title="Ready to mingle?" description={done()} />
                </Steps>
                
            <Modal width={600} closable={false} visible={isPaperModal} footer={[
                <Button key="submit" type="primary" disabled={myLikeIds.length < 2} onClick={async () => {
                    setIsPaperModal(false);
                    if(!users)
                        await fetchProfiles();
    
                
                    const simUsers = getSimiliarUsers({id: auth.currentUser.uid, myLikeIds: myLikeIds}, users.filter(u => u.id !== auth.currentUser.uid));
                    console.log(simUsers)
                    setSimiliarUsers(simUsers);
                }}>
                  Ok
                </Button>
              ]}>
                <PaperPick likes={myLikeIds} setLikePapers={setMyLikesIds} />
            </Modal>
            <Form
                name="basic"
                initialValues={{ remember: true }}
            >
                {/* search your papers */}
                {/*<Form.Item
                    name="this_year_paper"
                >
                    <h3>Select your names from proceedings of this conference.</h3>
                    
                    </Form.Item>*/}

                {/* select your interest */}
                {/* <SelectKeywords 
                    handleInterestChange={handleInterestChange} 
                    interests={interests}
                    selectedInterests={selectedInterests}
                />*/}

                {/* input your website */}
                {/*
                <Form.Item
                    name="website"
                    >
                    <h3>Your website URL <small>(optional)</small></h3>
                    <Input 
                        value={website}
                        onChange={handleWebsiteChange}
                        placeholder="https://www.acm.org"
                    />
                </Form.Item>
                */}

                {/* input your ACM username */}
                <Form.Item
                    name="acmusername"
                    >
                    {/* <h3>Automatic Data Synchronization with your paper interest <small>(optional)</small></h3> */}
                    {/* <h4 className="text-muted">To improve your matching to other attendees based on papers you've marked as interesting in the conference program. Please complete the following: </h4> */}
                    {/* <h4>Log in to your ACM first</h4> */}
                    { /* acmUsername ? <span>{acmUsername} (Successfully logged in)</span>:
                        <div><Button 
                            value={acmUsername}
                            onClick={(e) => {saveSettings();countDown()}}
                        >Log in to ACM</Button></div>
                    */} 
                    {/* <h4>Turn on the following data sharing (opt-in) at the SIGCHI app setting (<a href="https://programs.sigchi.org/settings" target="_blank" rel="noopener noreferrer">https://programs.sigchi.org/settings</a>) by agreeing to send deanonymized data:</h4> */}
                    {/*<img style={{"width": "400px"}} src={require("../images/acm-consent.png").default} alt="ACM setting page option" /><br/>*/}
                    
                </Form.Item>

                

            </Form>

            {/* <Button type="primary" size="large" onClick={() => saveSettings()}>Save</Button> <br/>*/}
            </div>
            : 
            <div>
                <h3>Consent</h3>
                <p>Use of this system is part of research being conducted by researchers at the Massachusetts Institute of Technology (MIT) to understand how different modes of communication can be supported in online groups. Your use of the system is voluntary, you may decline to answer any or all questions, and you can stop at any time. The system will record who you talk to and when but not the contents of your conversations. You may be contacted by email with surveys or other questions from the researchers. When the results of the research are made public, no information will be included that would reveal your identity.</p>
                <p>I consent to participate in this research by submitting the registration code below.</p>
                <h3>Registration code</h3>
                <Input id="regcode" style={{float:"left", width: "200px"}} />
                <Button type="primary" onClick={() => checkRegCode()}>I consent to participate in this research</Button>
            </div>
            }
            
            </>
            }
        </Col>
        
        <Col md={24} lg={12}>
            <div className="preview" style={{marginLeft: "20px"}}>
                <h3><b>Preview</b></h3>
                <div className={current === 0 ? "edit-blink": ""}>
                    {name || "[Your name]"}, {stage_dict[stage]} at {affiliation}
                    <br/><br/>
                { website &&
                    <>
                    <h4 className="text-muted">Personal website:</h4>
                    <div className="indent">
                        <a href={website} target="_blank">{website}</a>
                    </div>
                    </>
                }
                {/* <h4 className="text-muted">CSCW '22 publications</h4>
                {!author || author < 0 ?
                    <p>No paper at this conference"</p>
                    : proceeding["papers"].filter(p => p["authors"].includes(author)).map(p => 
                        <p>
                            <b>{p["title"]}</b> {p["authors"].map(a => proceeding["authors"][a]).join(", ")}
                            </p>)} */}
                </div>

                <div className={current === 1 ? "edit-blink": ""}>
                    <h4 className="text-muted">Recommended researchers to meet (ordered by relevance)</h4>
                    {"CHANGE TO token" ? <p>{similiarUsers.length ? 
                        <ul>{similiarUsers.slice(0,3).map(s => <li>{s.name} at {s.affiliation}</li>)}</ul>: 
                        <span><FileDoneOutlined />Recommendations are ready! Check the People page.</span>}</p> : <p>No recommendation available</p>}
                </div>
                
                <div className={current === 2 ? "edit-blink": ""}>
                <h4 className="text-muted">Previous publications</h4>
                {Object.keys(prev_papers).length === 0 ?
                    <p>No previous publication</p>:
                    Object.values(prev_papers).sort((a, b) => b.year - a.year).slice(0,3)
                        .map((v, index) => 
                        <p><b>{v["title"]}</b> {v["conference"]} {v["year"]}<br/>
                        <small>{v["authors"].slice(0, 7).map((a, i) => authorListFormat(v["authors"], i)).join(" ")}</small></p>)
                    // Object.keys(prev_papers)
                    //     .map((v, index) => 
                    //     <p><b>{prev_papers[v]["title"]}</b> {prev_papers[v]["conference"]} {prev_papers[v]["year"]}<br/>
                    //     <small>{prev_papers[v]["authors"]}</small></p>)
                    }
                </div>
            </div>
        </Col>
    </Row>
        
    </>
    );
}


export default Settings;